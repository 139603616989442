<div
  class="blogHeader"
  fxLayoutAlign="center center"
>
  <div class="blog">Blog Details</div>
</div>
<div
  fxLayout.lt-md="column-reverse"
  style="padding: 15px;"
>
  <div
    fxLayout="column"
    style=" border: 1px solid #eee;border-radius: 4px;"
  >
    <!-- <div class="PopularPost">Popular Post</div>
    <div
      style="border-bottom: 1px solid #eee;  padding: 10px;"
      *ngFor="let item of [1,2,3,4,5]"
      fxLayout="row"
      fxLayoutGap='10px'
      fxLayoutAlign.lt-md="start center"
      fxLayoutAlign="center center"
    >
      <div
        class="PopularPostImg"
        fxLayoutAlign="center center"
      >
        <img
          class="w100p"
          src="assets/img/welcome-bg.jpg"
        >
      </div>
      <div fxLayout="column">
        <div>05,Feb 2018</div>
        <div>Pramotes your App</div>
      </div>
    </div>
  </div> -->
    <div
      *ngIf="news"
      fxLayout="column"
      class="w100p"
      fxLayoutAlign="start center"
    >
      <img
        *ngIf="news?.images && news?.images[0]"
        [src]="news.images[0].url"
        class="w60p"
        ngClass.lt-md="w100p"
        style="border-radius: 4px;"
      >
      <img
        *ngIf="news.newsLink && news.newsLink[0]"
        [src]="news.newsLink[0].image"
        class="w60p"
        ngClass.lt-md="w100p"
        style="border-radius: 4px;"
      >
      <img
        *ngIf="news.video"
        [src]="news.video.image"
        class="w60p"
        ngClass.lt-md="w100p"
        style="border-radius: 4px;"
      >
      <div
        class="details w90p"
        fxLayoutGap="10px"
        style="padding: 10px; border-bottom: 1px solid #eee;"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start center"
      >
        <div
          fxLayout="row"
          fxLayoutGap="10px"
        >
          <div class="detailUser">{{news.author.name}}</div>
          <div>|</div>
          <div class="detailUser">{{news.createdAt}}</div>
          <div>|</div>
          <div class="detailUser">{{news.totalComment}} Comments</div>
        </div>
        <!-- <div
        fxFlex
        fxLayoutGap="10px"
        fxLayoutAlign="end start"
      >
        <mat-icon
          class="socialIcon s36"
          [svgIcon]="'assets/icons/facebook.svg' | svgIconUrl"
        ></mat-icon>
        <mat-icon
          class="socialIcon s36"
          [svgIcon]="'assets/icons/twitter.svg' | svgIconUrl"
        ></mat-icon>
        <mat-icon
          class="socialIcon s36"
          [svgIcon]="'assets/icons/google-plus.svg' | svgIconUrl"
        ></mat-icon>
      </div> -->
      </div>
      <div
        fxLayout="column"
        class="w90p"
        style="padding: 10px; border-bottom: 1px solid #eee;"
        fxLayoutGap="10px"
      >
        <div class="blogHeaderSec">{{news.title}}</div>
        <div class="blogContent">{{news.content}}</div>
        <a
          *ngIf="news.newsLink && news.newsLink[0]"
          [href]="news.newsLink[0].url"
          target="_blank"
        >
          {{news.newsLink[0].url}}
        </a>
        <a
          *ngIf="news.video"
          [href]="news.video.url"
          target="_blank"
        >
          {{news.video.url}}
        </a>
      </div>
    </div>
    <div class="commentArea">
      <div
        class="commentArea"
        style="    font-size: larger;
    font-weight: 400;
    margin-bottom: 25px;
    margin-left: 10px;
    margin-top: 25px;"
      >Comments</div>
      <div
        fxLayout="row"
        fxLayoutGap="10px"
      >
        <div
          class="userIcon"
          fxLayout="column"
          fxLayoutAlign="start center"
        >
          <img
            class="userPic"
            [ngStyle.lt-md]="{'width': '40px','height':'40px'}"
            src="assets/img/man.png"
          >
        </div>
        <div
          class="userComment"
          fxFlex
        >
          <textarea
            [ngStyle.lt-md]="{'width': '90%'}"
            placeholder="comment"
            style="border: none;"
            #content
          ></textarea>
          <div
            fxLayoutAlign.lt-md="end start"
            style="width: 100%;"
          >
            <button
              class="submit"
              (click)="createComment(content)"
            >Post</button>
          </div>
        </div>
      </div>
      <hr>
      <div
        *ngFor="let user of postComment"
        class="allComment"
        fxLayout="row"
      >
        <div
          fxLayout="column"
          class="userDetails"
          fxLayoutAlign="center center"
        >
          <div><img
              class="userPic"
              [src]="user?user.pic:'assets/img/man.png'"
            ></div>
          <div>{{user.author.name}}</div>
        </div>
        <div fxLayoutAlign="center center">{{user.message}}</div>
      </div>
    </div>
  </div>
