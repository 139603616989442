import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../authService/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signUpGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    username: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });
  public dialogRef: MatDialogRef<SignupComponent>;
  data: any;
  constructor(private authService: AuthService, private router: Router, private readonly dialogService: MatDialog,) { }

  ngOnInit(): void { }
  signUp(addUpdateDialog) {
    this.data = this.signUpGroup.value;
    console.log(this.data,"all data")
    this.authService.verifyEmailId(this.data.email).subscribe((result)=>{
      this.openNewsFeed(addUpdateDialog)
    })
    // this.authService.signup(data).subscribe((v: any) => {
    //   this.signUpGroup.reset();
    //   this.router.navigate(['/login'], { replaceUrl: true });
    // });
  }
  public openNewsFeed(addUpdateDialog) {
    this.dialogRef = this.dialogService.open(addUpdateDialog, {
      width: '500px',
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      console.log(result,"token result")
      // this.ItsForUpdate = false;
      // this.getData()
    });
  }
  closeToken(data){
    this.dialogRef.close();
    this.registerUser(data)
  }
  registerUser(token){
    this.data = this.signUpGroup.value;
    this.data.confPassword = this.data.password;
    this.data.meta = {};
    this.data.token = token;
    console.log(this.data);
    this.authService
      .registration(this.data)
      .pipe()
      .subscribe((v) => {
        console.log('success');
        // console.log(this.data.username);
        this.router.navigate(['/login']);
        // this.authService
        //   .login({this.data.username, this.data.password})
        //   .pipe()
        //   .subscribe(
        //     () => {
        //       console.log('success');
        //       // this.setUpAccountForm.reset();
        //     },
        //     (error) => {
        //       const a = error;
        //       // console.log(a.error.message);
        //       this.snackService.error(a.error.message);
        //     }
        //   );
      });
  }
}
