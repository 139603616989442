import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AuthService } from '../authService/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isRateLimitReached: boolean;
  resultsLength: any;
  constructor(private authService: AuthService) { }
  news = [];
  ngAfterViewInit(): void {

    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.authService!.getNews(this.paginator.pageIndex).pipe(
            catchError(() => of(null))
          );
        }),
        map((result: any) => {
          this.isRateLimitReached =
            Number(result.meta.currentPage) === result.meta.lastPage;

          if (result === null) {
            return [];
          }
          this.resultsLength = result.meta.total;
          return result.data;
        })
      )
      .subscribe((data) => {
        data.map(v => {
          v.createdAt = moment(v.createdAt).fromNow();
          v.images = v.images ? v.images : []
          v.newsLink = JSON.parse(v.newsLink)
          v.video = JSON.parse(v.video)
        })

        console.log(data, "all post")
        this.news = data

      });
  }

}
