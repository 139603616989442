import { Injectable } from '@angular/core';

declare const FB: any;

const loadJs = (url: string) => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`head > script[ src = "${url}" ]`) !== null) {
      resolve(true);
    }
    const script = document.createElement('script');
    script.src = url;
    script.onload = resolve;
    script.onerror = (reason) => reject(reason);
    document.head.appendChild(script);
  });
};

@Injectable({ providedIn: 'root' })
export class FBService {
  public initParams = {
    appId: '145815390374645', // WebiersId
    autoLogAppEvents: true,
    cookie: true,
    xfbml: true,
    version: 'v9.0',
  };

  private fields = [
    'id',
    'name',
    'email',
    'first_name',
    'last_name',
    'picture.width(720).height(720).as(picture_large)',
  ];

  private sdkLoaded: any;
  constructor() {}

  public async login() {
    return this.webLogin();
  }

  // ionic login with facebook

  // public async nativeLogin() {
  //   const fb = this.facebook;
  //   const permissions = ['email', 'public_profile'];
  //   const response: FacebookLoginResponse = await fb.login(permissions);
  //   if (response.authResponse) {
  //     const apiUrl = `me?fields=${this.fields.join(',')}`;
  //     const user = await fb.api(apiUrl, []);
  //     return user;
  //   } else {
  //     throw new Error('FB Auth Error');
  //   }
  // }

  private async loadWebSdk() {
    await loadJs('https://connect.facebook.net/en_US/sdk.js');
    FB.init(this.initParams);
    return FB;
  }

  private getWebSdk() {
    if (!this.sdkLoaded) {
      this.sdkLoaded = this.loadWebSdk();
    }
    return this.sdkLoaded;
  }

  public async webLogin() {
    await this.getWebSdk();

    const response: any = await new Promise((s) =>
      FB.login(s, { scope: 'email' })
    );
    if (response.authResponse) {
      const user = await new Promise((s) =>
        FB.api('/me', { fields: this.fields.join(',') }, s)
      );
      return user;
    } else {
      throw new Error('FB Auth Error');
    }
  }
}
