import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './authService/auth.interceptor';
import { BlogComponent } from './blog/blog.component';
import { FullPageBlogComponent } from './full-page-blog/full-page-blog.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { MaterialModule } from './pipes/material.module';
import { NkPipesModule } from './pipes/pipes.module';
import { SignupComponent } from './signup/signup.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FeaturesComponent } from './features/features.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    BlogComponent,
    LoginComponent,
    SignupComponent,
    FullPageBlogComponent,
    AboutUsComponent,
    FeaturesComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatMenuModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    NkPipesModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
