<mat-drawer-container
  class="example-container"
  [hasBackdrop]="true"
>
  <mat-drawer
    class="w260"
    #drawer
    mode="over"
  >
    <div
      fxLayout="column"
      *ngIf="authService.userDetail"
    >
      <div
        class="drawerDesign"
        *ngFor="let item of menusList"
      >
        <div
          [routerLink]="[ '/',item.menuUrl ]"
          style="color:#54565a; outline: none;"
        >{{item.menuTitle}}</div>
      </div>
      <div
        fxLayout="row"
        class="drawerDesign"
        fxLayoutAlign="start center"
        style="cursor: pointer;"
        fxLayoutGap="5px"
      >
        <div class="s28"><img
            class="w100p"
            [src]="authService.userDetail.pic"
          ></div>
        <div
          style="color:#54565a; cursor: pointer;"
          (click)="accounts = !accounts"
        >{{authService.userDetail.name}}</div>
        <div>
          <mat-icon
            style="color: #54565a;"
            [ngStyle]="{'transform':(accounts?'rotate(180deg)':'')}"
            [svgIcon]="'assets/icons/downArrow.svg' | svgIconUrl"
            class="downAroww"
          >
          </mat-icon>
        </div>
      </div>
      <div
        style="cursor: pointer;"
        *ngIf="!accounts"
        (click)="  logout()"
      >
        <div style="padding: 15px;">Logout</div>
      </div>
    </div>
    <div
      fxLayout="column"
      *ngIf="!authService.userDetail"
    >
      <div
        class="drawerDesign"
        *ngFor="let item of menusList"
      >
        <div
          [routerLink]="[ '/',item.menuUrl ]"
          style="color:#54565a; outline: none;"
        >{{item.menuTitle}}</div>
      </div>
      <!-- <div
        fxLayout="row"
        class="drawerDesign"
        fxLayoutAlign="start center"
        style="cursor: pointer;"
        fxLayoutGap="5px"
      >
        <div
          style="color:#54565a;cursor: pointer;"
          (click)="accounts = !accounts"
        >Accounts</div>
        <div>
          <mat-icon
            style="color: #54565a;"
            [ngStyle]="{'transform':(accounts?'rotate(180deg)':'')}"
            [svgIcon]="'assets/icons/downArrow.svg' | svgIconUrl"
            class="downAroww"
          >
          </mat-icon>
        </div>
      </div> -->
      <!-- <div
        style="padding: 15px;cursor: pointer"
        fxLayout="column"
        fxLayoutGap="16px"
        *ngIf="accounts"
      >
        <div (click)="login()">LOGIN</div>
        <div routerLink="signup">SIGNUP</div>
        <div>RESET PASSWORD</div>
      </div> -->
    </div>
  </mat-drawer>
  <mat-drawer-content (scroll)="hello($event)">
    <!-- blue header -->
    <div
      fxLayout="row"
      class="header"
      [ngStyle.lt-md]="{'padding': '10px 16px 10px 16px'}"
      [ngStyle.gt-sm]="{'padding-left': '100px','padding-right': '100px'}"
    >
      <div
        class="headerWork"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div fxFlex><img
            routerLink=""
            class="logo"
            src="assets/img/logo-white.png"
          ></div>
        <div
          fxLayout="row"
          fxHide.lt-md="true"
          fxLayoutAlign="center center"
          fxLayoutGap="25px"
        >
          <div
            routerLink=""
            class="tabs"
          >Home</div>
          <div
            routerLink="Features"
            class="tabs"
          >Feature</div>
          <div
            routerLink="aboutUs"
            class="tabs"
          >About us</div>
          <div
            routerLink="blog"
            class="tabs"
          >Blog</div>
          <div
            *ngIf="!userLogin"
            routerLink="auth"
            class="tabs"
          >Login/SignUp</div>
          <div
            *ngIf="userLogin"
            [matMenuTriggerFor]="menu"
            fxLayout="row"
            fxLayoutAlign="center center"
            style="cursor: pointer;"
          >
              <div
                class="tabs"
              >{{userLogin.data.name}}</div>
              <mat-icon  style="color: white;">expand_more</mat-icon>
            </div>
          <div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click) = "logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
              </button>
            </mat-menu>
            <!-- whenUser is not Login -->
            <!-- <!-- <div
              *ngIf="!authService.userDetail"
              [matMenuTriggerFor]="menu"
              fxLayout="row"
              fxLayoutGap="5px"
              fxLayoutAlign="center center"
            >
              <div class="tabs">Accounts
              </div>
              <mat-icon
                [svgIcon]="'assets/icons/downArrow.svg' | svgIconUrl"
                class="downAroww"
              >
              </mat-icon>
              <mat-menu #menu="matMenu">
                <button
                  (click)="login()"
                  mat-menu-item
                >
                  <mat-icon>login</mat-icon>
                  <span>LOGIN</span>
                </button>
                <button
                  mat-menu-item
                  routerLink="signup"
                >
                  <mat-icon>person_add</mat-icon>
                  <span>SIGNUP</span>
                </button>
              </mat-menu>
            </div>
            <!-- whenUser is Login -->
            <!-- <div
              *ngIf="authService.userDetail"
              [matMenuTriggerFor]="blueMenu"
              fxLayout="row"
              fxLayoutGap="5px"
              fxLayoutAlign="center center"
            >
              <div class="s28">
                <img
                  class="w100p"
                  [src]="authService.userDetail.pic"
                  alt=""
                >
              </div>
              <div class="tabs">{{authService.userDetail.name}}
              </div>
              <mat-icon
                [svgIcon]="'assets/icons/downArrow.svg' | svgIconUrl"
                class="downAroww"
              >
              </mat-icon>
              <mat-menu #blueMenu="matMenu">
                <button
                  mat-menu-item
                  (click)="  logout()"
                >
                  <mat-icon>logout</mat-icon>
                  <span>Logout</span>
                </button>
              </mat-menu>
            </div> -->
          </div>
        </div>
        <div>
          <mat-icon
            fxHide
            fxShow.lt-md="true"
            class="white-fg"
            style="cursor: pointer; width: 50px;height: 50px; color: rgba(255, 255, 255, 0.897) !important;"
            (click)="drawer.toggle()"
            [svgIcon]="'assets/icons/bars.svg' | svgIconUrl"
          >
          </mat-icon>
        </div>
      </div>
    </div>
    <!-- White Header -->
    <div
      fxLayout="row"
      class="second-header"
      [ngClass]="{'show-header':floatingNavBar | async}"
      [ngStyle.lt-md]="{'padding': '10px 16px 10px 16px'}"
      [ngStyle.gt-sm]="{'padding-left': '100px','padding-right': '100px'}"
    >
      <div
        class="headerWork"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div fxFlex><img
            routerLink=""
            class="logo"
            src="assets/img/logo.png"
          ></div>
        <div
          fxLayout="row"
          fxHide.lt-md="true"
          fxLayoutAlign="center center"
          fxLayoutGap="25px"
        >
          <div
            routerLink=""
            class="tabs"
            style="color: #7e8085 !important;"
          >Home</div>
          <div
            style="color: #7e8085 !important;"
            class="tabs"
            routerLink="Features"
          >Feature</div>
          <div
            routerLink="aboutUs"
            style="color: #7e8085 !important;"
            class="tabs"
          >About us</div>
          <div
            routerLink="blog"
            class="tabs"
            style="color: #7e8085 !important;"
          >Blog</div>
          <div
          *ngIf="!userLogin"
          routerLink="auth"
          class="tabs"
          style="color: #7e8085 !important;"
        >Login/SignUp</div>
        <div
          *ngIf="userLogin"
          [matMenuTriggerFor]="menu"
          fxLayout="row"
          fxLayoutAlign="center center"
          style="cursor: pointer;"

        >
            <div
              class="tabs"
              style="color: #7e8085 !important;"
            >{{userLogin.data.name}}</div>
            <mat-icon  style="color: #7e8085;">expand_more</mat-icon>
          </div>
          <div>
            <!-- whenUser is not Login -->
            <!-- <div
              *ngIf="!authService.userDetail"
              [matMenuTriggerFor]="menu"
              fxLayout="row"
              fxLayoutGap="5px"
              fxLayoutAlign="center center"
            >
              <div
                style="color: #7e8085 !important;"
                class="tabs"
              >Accounts
              </div>
              <mat-icon
                style="color: #7e8085 !important;"
                [svgIcon]="'assets/icons/downArrow.svg' | svgIconUrl"
                class="downAroww"
              >
              </mat-icon>
              <mat-menu #menu="matMenu">
                <button
                  (click)="login()"
                  mat-menu-item
                >
                  <mat-icon>login</mat-icon>
                  <span>LOGIN</span>
                </button>
                <button
                  mat-menu-item
                  routerLink="signup"
                >
                  <mat-icon>person_add</mat-icon>
                  <span>SIGNUP</span>
                </button>
              </mat-menu>
            </div> -->
            <!-- whenUser is Login -->
            <!-- <div
              *ngIf="authService.userDetail"
              [matMenuTriggerFor]="blueMenu"
              fxLayout="row"
              fxLayoutGap="5px"
              fxLayoutAlign="center center"
            >
              <div class="s28"><img
                  class="w100p"
                  [src]="authService.userDetail.pic"
                  alt=""
                ></div>
              <div
                style="color: #7e8085 !important;"
                class="tabs"
              >{{authService.userDetail.name}}
              </div>
              <mat-icon
                style="color: #7e8085 !important;"
                [svgIcon]="'assets/icons/downArrow.svg' | svgIconUrl"
                class="downAroww"
              >
              </mat-icon>
              <mat-menu #blueMenu="matMenu">
                <button
                  mat-menu-item
                  (click)="  logout()"
                >
                  <mat-icon>logout</mat-icon>
                  <span>Logout</span>
                </button>
              </mat-menu>
            </div> -->
          </div>
        </div>
        <div>
          <mat-icon
            fxHide
            fxShow.lt-md="true"
            style="cursor: pointer; width: 50px;height: 50px; color: #7e8085 !important;"
            (click)="drawer.toggle()"
            [svgIcon]="'assets/icons/bars.svg' | svgIconUrl"
          >
          </mat-icon>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
    <!-- footer  -->
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="footer w100p"
    >
      <div><img
          class="webiersLogo"
          src="assets/img/logo.png"
        ></div>
      <div class="footerHeading">If you have any questions, have feedback, or requests, please don’t hesitate to contact us.We’re looking forward to hearing from you!</div>
      <div
        fxLayoutGap="10px"
        fxLayout="row"
        fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="12px"
        class="w100p"
        style="text-align: center; cursor: pointer;"
        fxLayoutAlign="center center"
      >
        <!-- <div fxFlex>Affiliate program</div> -->
        <!-- <div>Terms & Conditions</div> -->
        <a routerLink="PrivacyPolicy">Privacy Policy</a>
        <!-- <div fxFlex>Refund Policy</div> -->
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
