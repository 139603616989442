<div
  class="blogHeader"
  fxLayoutAlign="center center"
>
  <div class="blog">Features</div>
</div> <!-- features  -->
<div style="margin-top:125px">
  <div
    class="webiersGroup"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      class="webiersHeader mat-elevation-z3 "
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
    >
      <mat-icon
        style="color:#7c4fe0"
        [svgIcon]="'assets/icons/idea.svg' | svgIconUrl"
      >
      </mat-icon>
      <div style="color:#7c4fe0">Premium</div>
      <div>Features</div>
    </div>
    <div class="WebiersContent">What Makes Webiers Different?</div>
    <div
      class="w10p"
      style="height:1.5px;background:#7c4fe0; margin-bottom: 10px;"
    ></div>
    <div class="webiersDeatil">Webiers is made with the utmost care and professionalism. We aim at bringing content, people, and memberships collectively in a private and secure community platform. We pride ourselves on our people-centric model that puts YOU ahead of everything else.</div>
    <div
      fxLayout="row wrap"
      fxLayout.lt-md="column"
      fxLayoutAlign="center center"
      style="margin-top: 25px; max-width:80%;"
    >
      <div
        class="card  mat-elevation-z3"
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        *ngFor="let item of features"
      >
        <div><img
            class="featureImg"
            [src]="item.icon"
          ></div>
        <div class="featuresHeader">{{item.header}}</div>
        <div class="featuresDetail">{{item.detail}}</div>
      </div>
    </div>
  </div>
</div>
