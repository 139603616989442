import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {
  features = [
    {
      icon: 'assets/img/featured_image_1.png',
      header: 'Community Ready',
      detail:
        'Webiers is fully featured and ready to go. The platform will satisfy all your community-building needs and more.',
    },
    {
      icon: 'assets/img/featured_image_2.png',
      header: 'Live Chat',
      detail:
        'Connect with your very own community with our multiplatform support for any device. Meet and engage in a secure and safe place.',
    },
    {
      icon: 'assets/img/featured_image_3.png',
      header: 'Secure Data',
      detail:
        'Webiers offers a secure and safe place for all your community needs. Own and control your own space without any worries.',
    },
    {
      icon: 'assets/img/featured_image_4.png',
      header: 'CMS',
      detail:
        'Promote your content to your audience using our Content Management System.',
    },
    {
      icon: 'assets/img/featured_image_5.png',
      header: 'Posts',
      detail:
        'Let your audience view and share content in the form of stories, photos, videos, and audios.',
    },
    {
      icon: 'assets/img/featured_image_6.png',
      header: 'Social Authorization',
      detail: 'Log in via FB,Google or Apple account.',
    },
    {
      icon: 'assets/img/featured_image_7.png',
      header: 'Notifications',
      detail:
        'Inform users whenever an interaction takes place by sending push notifications.',
    },
    {
      icon: 'assets/img/featured_image_8.png',
      header: 'Customized Modules',
      detail: 'Contact us for customized modules for your community app.',
    },
    {
      icon: 'assets/img/featured_image_9.png',
      header: 'Network Building',
      detail: 'Create social network by adding or following people easily.',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
