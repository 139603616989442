<div
  class="blogHeader"
  fxLayoutAlign="center center"
>
  <div class="blog">Need A Solution? Look Here</div>
</div>
<div style="margin: auto;">
  <div class="WebiersContent">About Us</div>
  <div
    class="w10p"
    style=" margin: auto;height:1.5px;background:#7c4fe0; margin-bottom: 10px;"
  ></div>
  <div class="webiersDeatil">Welcome to Webiers, your number one source for all your community-building needs. We're dedicated to providing you the very best app out there, with an emphasis on cross-platform support, user experience, and security. We hope you enjoy our application as much as we enjoyed making it for you. If you have any questions or comments, please don't hesitate to contact us. We are a team of dynamic individuals strive at providing solutions for small to large entities. Our problem solving approach is very unique, we strive at identifying big or small problems.</div>
</div>
<!-- stayTuned -->
<div
  class="StayTunedContener"
  fxLayoutAlign="center center"
  fxLayout="column"
  style="padding:15px"
>
  <div class="StayTunedHeader">Stay Tuned</div>
  <div
    class="w10p"
    style="height:1.5px;background:#7c4fe0; margin-bottom: 10px; margin-top: 10px;"
  ></div>
  <div class="StayTunedContener">If you have any questions, have feedback, or requests, please don’t hesitate to contact us from the form below. We’re looking forward to hearing from you!</div>
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    class="w100p"
  >
    <div
      fxFlex
      fxLayout="column"
      fxLayoutGap="10px"
      fxLayoutAlign="center center"
    >
      <div
        fxLayout="column"
        fxLayoutGap="10px"
      >
        <div
          fxLayout="row"
          fxLayoutGap="10px"
          fxLayoutAlign="start center"
          *ngFor="let item of icon"
        >
          <div
            class="iconBox"
            fxFlex="54px"
          >
            <mat-icon [svgIcon]="item.iconTwo | svgIconUrl">
            </mat-icon>
          </div>
          <div>{{item.compDetail}}</div>
        </div>
      </div>
    </div>
    <div
      fxFlex
      fxLayout="column"
      [ngStyle.lt-sm]="{'padding': '0'}"
      style="padding-left: 10%;padding-right: 10%;"
    ><br>
      <input
        class="formText"
        type="text"
        placeholder="Name"
      ><br>
      <input
        class="formText"
        type="email"
        placeholder="Email"
      ><br>
      <input
        class="formText"
        type="text"
        placeholder="Subject"
      ><br>
      <textarea
        class="formTextarea"
        placeholder="Message"
        cols="30"
        rows="10"
      ></textarea>
      <button class="SubscribeContentBtn">Send Message</button>
    </div>
  </div>
</div>
<!-- <div
  fxLayout="column"
  fxLayoutAlign="center center"
  class="footer w100p"
>
  <div><img
      class="webiersLogo"
      src="assets/img/logo.png"
    ></div>
  <div class="footerHeading">If you have any questions, have feedback, or requests, please don’t hesitate to contact us.We’re looking forward to hearing from you!</div>
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutGap.lt-sm="12px"
    class="w100p"
    style="text-align: center; cursor: pointer;"
    fxLayoutAlign="center center"
  >
    <div fxFlex>Affiliate program</div>
    <div fxFlex>Terms & Conditions</div>
    <div fxFlex>Privacy Policy</div>
    <div fxFlex>Refund Policy</div>
  </div>
</div> -->
