import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { snakeCase } from 'change-case';
import { AuthService } from '../authService/auth.service';
import { FBService } from '../services/fb.service';
import { UserGlobalService } from '../services/user-global.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  userDone = false;
  loginGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  toRedirect = '/';
  constructor(
    private authService: AuthService,
    private fBService: FBService,
    private userGlobalService: UserGlobalService,

    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((v) => {
      if (v.has('r')) {
        const url = v.get('r');
        if (!url.includes('signup')) {
          this.toRedirect = url;
        }
      }
    });
  }
  signIn() {
    this.authService.login(this.loginGroup.value).subscribe((v: any) => {
      localStorage.setItem('token', v.accessToken);
      console.log(v, "data")
      this.loginGroup.reset();
      this.authService.profile().subscribe(async (v) => {
        this.userDone = true;

        this.userGlobalService.userLogin(v);
        const loginUser = this.userGlobalService.LoginUserDetail;
        console.log(loginUser, "loginUser")
       await this.router.navigate([this.toRedirect]);

        location.reload();
      });
    });
  }

  async fbLogin() {
    const user = await this.fBService.login();
    if (user) {
      this.fbReg(user);
    } else {
      alert('Fb login not success');
    }
  }
  public fbReg(fbData: any) {
    console.log(fbData, 'fbData');
    const data: any = {};
    const username =
      snakeCase(fbData.name) + (1e4 + Math.floor(Math.random() * 9e4));

    data.email = fbData.email;
    // data.gender = undefined;
    data.imageUrl = fbData.picture_large.data.url;
    data.meta = { src: 'fb', id: fbData.id };
    // data.mobile = undefined;
    data.name = fbData.name;
    data.password = fbData.id;
    data.username = username;

    this.authService.fbLogin(data).subscribe((v: any) => {
      localStorage.setItem('token', v.access_token);
      this.authService.profile().subscribe((v) => {
        console.log(v);
        this.router.navigate([this.toRedirect]);
      });
    });
  }
}
