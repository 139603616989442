import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from '../authService/auth.service';
@Component({
  selector: 'app-full-page-blog',
  templateUrl: './full-page-blog.component.html',
  styleUrls: ['./full-page-blog.component.scss'],
})
export class FullPageBlogComponent implements OnInit {
  news;
  public jData = {
    filter: { condition: 'AND', rules: [], valid: true },
    config: { sort: 'createdAt', order: 'DESC', page: 1, limit: 8 },
  };
  newsLink: any;
  postComment: any;
  postId: any;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { }

  async ngOnInit(): Promise<void> {
   await this.route.paramMap.subscribe((v) => {
      const id = v.get('id');
      this.authService.getSingleNews(id).subscribe((v: any) => {
        v.createdAt = moment(v.createdAt).fromNow();
        if (v.newsLink) {
          v.newsLink = JSON.parse(v.newsLink);

        }
        if (v.video) {
          v.video = JSON.parse(v.video);

        }
        this.news = v;
        this.getComment()
        console.log(this.news, 'news');
      });
    });

  }
  createComment(comment) {
 const token = localStorage.getItem("token")
 console.log(token,"tokenj")
 if(token){

   this.authService
     .createComment(this.news.id, comment.value)
     .subscribe(async (v) => {
      comment.value = ''
      this. getComment()
       this._snackBar.open('Comment Created')
     })
 }
 else{
  this.router.navigate(['/login'])
 }
  }
  public getComment() {
    this.authService
      .getComment(this.news.id, this.jData)
      .subscribe((v: any) => {
        console.log(v,"comment")
        this.postComment = v.data.map((k) => {
          k.postId = this.postId;
          k.createdAt = k.createdAt;
          k.openReply = false;
          return k;
        });
      });
  }
}
