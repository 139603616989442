import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  counts = [
    {
      type: 'Users',
      value: interval(100).pipe(
        take(50),
        map((v) => `${(v / 20).toFixed(2)}M`)
      ),
    },
    {
      type: 'Download',
      value: interval(100).pipe(
        take(50),
        map((v) => `${(v * 4).toFixed(2)}K`)
      ),
    },
    {
      type: 'Customer',
      value: interval(100).pipe(
        take(50),
        map((v) => `${(v * 0.2).toFixed(2)}M`)
      ),
    },
    {
      type: 'Developer',
      value: interval(100).pipe(
        take(50),
        map((v) => `${(v * 2).toFixed(2)}K`)
      ),
    },
  ];
  features = [
    {
      icon: 'assets/img/featured_image_1.png',
      header: 'Community Ready',
      detail:
        'Webiers is fully featured and ready to go. The platform will satisfy all your community-building needs and more.',
    },
    {
      icon: 'assets/img/featured_image_2.png',
      header: 'Live Chat',
      detail:
        'Connect with your very own community with our multiplatform support for any device. Meet and engage in a secure and safe place.',
    },
    {
      icon: 'assets/img/featured_image_3.png',
      header: 'Secure Data',
      detail:
        'Webiers offers a secure and safe place for all your community needs. Own and control your own space without any worries.',
    },
  ];
  device = [
    {
      icon: 'assets/icons/buffer.svg',
      header: 'Analytics to discover, understand and improve your community.',
    },
    {
      icon: 'assets/icons/brush.svg',
      header: 'Content library for a centralized place for all your assets.',
    },
    {
      icon: 'assets/icons/burn.svg',
      header: 'Moderation for full control over the community and its members.',
    },
    {
      icon: 'assets/icons/cart.svg',
      header:
        'API & Integration to add news & content to third party websites and apps.',
    },
  ];
  checkDevice = [
    {
      icon: 'assets/icons/check.svg',
      header: 'Push notifications to stay on top of everything.',
    },
    {
      icon: 'assets/icons/check.svg',
      header: 'Event planner to manage meetings and important events.',
    },
    {
      icon: 'assets/icons/check.svg',
      header: 'Live chat for a direct and easy way to connect and engage..',
    },
    {
      icon: 'assets/icons/check.svg',
      header: 'Unlimited groups for subcommunities and different niches.',
    },
  ];
  icon = [
    {
      iconOne: 'assets/icons/bell.svg',
      iconTwo: 'assets/icons/home.svg',
    },
    {
      iconOne: 'assets/icons/envelope.svg',
      iconTwo: 'assets/icons/phone.svg',
    },
    {
      iconOne: 'assets/icons/video.svg',
      iconTwo: 'assets/icons/msg.svg',
    },
  ];

  review = [
    {
      customerReview: 'Excellent service & support!!',
      customer: 'Hakim A',
      customerComp: 'CEO, Nibbles',
    },
    {
      customerReview: 'Nice work! Keep it up',
      customer: 'Jeff CHua',
      customerComp: 'CEO, Askmisterchua',
    },
    {
      customerReview: 'Great support!!',
      customer: 'Sam A.',
      customerComp: 'CEO, Trigger Solution',
    },
  ];

  askedQuestionsSectionOne = [
    {
      question: 'Which platforms are supported?',
      answer: `We have multi-platform support for iOS (Apple's operating system) and Android (Google's operating system, which is widely available on most non-apple users). You can find the app on Apple's App Store and Google's Play Store. Do you have another platform you want us to include? You can reach out to us, and we'll work on integrating the app with the platforms you suggest.`,
    },
    {
      question: ' How quickly do you fix reported bugs? ',
      answer: `We do meticulous quality checks and tests before releasing the app, and we keep a constant lookout for any bugs or issues. Were continuously working on updating the app and adding new features that benefit all on both iOS and Android. But even with the most careful screening for problems, some of them do find their way into the app. If you're experiencing an issue, please contact our qualified Customer Service and we'll fix any problems promptly.`,
    },
    {
      question: 'Do I need to be a programmer? ',
      answer: `Definitely not. We made our app to be as user friendly and intuitive as possible. As soon as you jump in, you’ll find yourself getting the hang of everything.`,
    },
  ];

  webiersDetail = [
    {
      icon: 'assets/img/work_thumb_1.png',
      header: 'Book Demo',
      content:
        'We will assign our Sales Representative to get all the required information.',
    },
    {
      icon: 'assets/img/work_thumb_2.png',
      header: '1 week Trial',
      content:
        'You can access the full features of Webiers App throughout the trial period.',
    },
    {
      icon: 'assets/img/work_thumb_3.png',
      header: 'Publish!',
      content:
        'Upon approval, we will publish your very own Community app in both Android and IOS immediately.',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
