<div
  class="loginsection"
  fxFlex
>
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    ngClass.gt-xs="h100p"
    fxLayoutAlign="center center"
  >
    <div
      class="wellcome"
      fxFlex
    >Welcome back!</div>
    <div
      fxFlex
      ngStyle.lt-md="'padding-bottom':'50px'"
      fxLayoutAlign="center center"
    >
      <form
        [formGroup]="loginGroup"
        class="loginCont"
        ngClass.gt-xs="w400"
      >
        <div>Sign In</div>
        <div>Fill all fields so we can get some <br>info about you. We'll never send you spam</div>
        <div
          fxLayout="row"
          fxLayoutAlign="center"
          style="margin-top: 20px;"
        >
          <div class="envolop">
            <mat-icon [svgIcon]="'assets/icons/envelope.svg' | svgIconUrl"></mat-icon>
          </div>
          <input
            formControlName="username"
            type="email"
            class="email"
            placeholder="Email"
          >
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center"
          style="margin-top: 20px;"
        >
          <div class="envolop">
            <mat-icon [svgIcon]="'assets/icons/unlock.svg' | svgIconUrl"></mat-icon>
          </div>
          <input
            formControlName="password"
            type="password"
            class="email"
            placeholder="Password"
          >
        </div>
        <div fxLayoutAlign="center">
          <button
            (click)="signIn()"
            class="btn"
          >Sign In</button>
        </div><br>
        <mat-icon
          (click)="fbLogin()"
          class="s30"
          style="margin: auto; cursor: pointer;"
          [svgIcon]="'assets/icons/facebook.svg' | svgIconUrl"
        ></mat-icon>
        <br>
        <hr>
        <div>Don't have an account?</div>
        <div
          routerLink="/signup"
          class="signUp"
        > Sign Up</div>
      </form>
    </div>
  </div>
</div>
<mat-icon
  class="curvedDesign"
  [svgIcon]="'assets/icons/curvedDesign.svg' | svgIconUrl"
></mat-icon>
