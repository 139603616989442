import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './authService/auth.service';
import { floatingNavBar$ } from './services/floating-navbar.service';
import { LoadHelperStyleSheet } from './services/theme/style-injector.service';
import { UserGlobalService } from './services/user-global.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'webiers';
  floatingNavBar = floatingNavBar$;
  menusList = [
    { menuTitle: 'Home', menuUrl: '/' },
    { menuTitle: 'Features', menuUrl: 'Features' },
    { menuTitle: 'About us', menuUrl: 'aboutUs' },
    { menuTitle: 'Blog', menuUrl: 'blog' },
    { menuTitle: 'Login/SignUp', menuUrl: 'blog' },
  ];
  accounts = false;
  userLogin: any;

  constructor(private router: Router, public authService: AuthService, private userGlobalService: UserGlobalService,) {
    this.userDetail()
   }
  ngOnInit(): void {
    LoadHelperStyleSheet();

  }
  hello(e) {
    if (e.target.scrollTop > 600) {
      floatingNavBar$.next(true);
    } else {
      floatingNavBar$.next(false);
    }
  }
  userDetail() {
    const token = localStorage.getItem('token')
    if (token) {

      this.authService.profile().subscribe((v) => {
        this.userLogin = v
        console.log(this.userLogin, "userLogin123")
      })
    }

  }
  login() {
    let url = this.router.url;
    if (url.includes('login')) {
      url = '/';
    }
    this.router.navigate(['/login'], {
      queryParams: {
        r: url,
      },
    });
  }
  logout() {
    localStorage.clear();
    window.location.reload();
  }
}
