import { Injectable } from '@angular/core';









@Injectable({ providedIn: 'root' })
export class UserGlobalService {
  public UserData: any = null;
  // private MboStatusData = null;
  private _auth: any = null;
  // private adminPermission = [];
  // private _userPermission = [];

  LoginUserDetail: any;
  constructor(


  ) {}

  // public updatePermissions(userDetails: any) {
  //   const acl = ACLBuilder.build(genActiveUser(userDetails));
  //   this.ability.update(acl.rules);
  //   this.acl = acl;
  //   console.log(10 ** 10, this.ability);
  //   const menu = genPer(acl);
  //   this.store.dispatch(new ResetMenu());
  //   menu.forEach((v) => {
  //     this.store.dispatch(new AddMenuGroup(v));
  //   });
  // }

  // public copyText(page: string, id: string | number) {
  //   const url = Domain + page + '/' + id;
  //   this.clipboardService.copyFromContent(url);
  //   this.snackService.success('Url copied', 'Successful');
  // }

  userLogin(data){
    this.LoginUserDetail = data;

  }

  // get user() {
  //   if (this.UserData) {
  //     return this.UserData;
  //   }
  //   if (localStorage.getItem(DataBaseKeys.User)) {
  //     this.UserData = JSON.parse(
  //       atob(localStorage.getItem(DataBaseKeys.User) || '')
  //     );
  //     this.onUserLogin.next(this.UserData);
  //   } else {
  //     this.UserData = null;
  //   }
  //   return this.UserData || {};
  // }

  // // get MboStatus() {
  // //   if (this.MboStatusData) {
  // //     return this.MboStatusData;
  // //   }
  // //   if (localStorage.getItem(DataBaseKeys.MBO)) {
  // //     this.MboStatusData = JSON.parse(
  // //       atob(localStorage.getItem(DataBaseKeys.MBO))
  // //     );
  // //     this.onMBOStatus.next(this.MboStatusData);
  // //   } else {
  // //     this.MboStatusData = null;
  // //   }
  // //   return this.MboStatusData || {};
  // // }

  // set user(val) {
  //   this.UserData = null;
  //   // this.MboStatusData = null;
  //   localStorage.setItem(DataBaseKeys.User, btoa(JSON.stringify(val)));
  //   this.UserData = { ...val };
  //   this.onUserLogin.next(this.UserData);
  // }

  // // set MboStatus(value) {
  // //   localStorage.setItem(DataBaseKeys.MBO, btoa(JSON.stringify(value)));
  // //   this.MboStatusData = { ...value };
  // //   this.onMBOStatus.next(this.MboStatusData);
  // // }

  // // public MboId() {

  // //   return this.MboStatus['mboId'] || null;
  // // }

  // // public MboGetStatus() {

  // //   return this.MboStatus['status'] || null;
  // // }

  // public userRole() {
  //   // console.log(this.UserData['role']);
  //   return this.user['role'] || [];
  // }
  // public hasPermission(ar: string[]) {
  //   return !ar ? true : ar.some((v) => this.userRole().includes(v));
  // }

  // // public isUserLogin() {
  // //   return this.userRole().length > 0;
  // // }

  // // public getUserId() {
  // //   return this.user['id'] || null;
  // // }

  // // public getMembershipNo() {
  // //   return this.user['memberShipNo'] || null;
  // // }

  // // public getUserName() {
  // //   // console.log("user name ", this.user['username'] );
  // //   return this.user['username'] || null;
  // // }

  // // public getUserProfile() {
  // //   return this.user['profile_image'] || null;
  // // }

  // // private userPermission() {
  // //   if (this.isAdmin()) {
  // //     return this.adminPermission;
  // //   } else if (this.isMember()) {
  // //     return this._userPermission;
  // //   }
  // // }

  // // public hasPermission(ar) {
  // //   return !ar ? true : ar.some((v) => this.userRole().includes(v));
  // // }

  // // private leadingGroups() {
  // //   return this.user.leading_groups || [];
  // // }

  // // private isLeadingGroup(groups) {
  // //   return this.user.leading_groups.some((v) => groups.includes(v.name));
  // // }

  // public isAdmin() {
  //   return this.userRole().includes(Roles.admin);
  // }

  // public isSuperAdmin() {
  //   return this.userRole().includes(Roles.super_admin);
  // }

  // public isMember() {
  //   return this.userRole().includes(Roles.member);
  // }

  // // public isMBOEditor() {
  // //   return this.userRole().includes(Roles.mbo_editor);
  // // }

  // public isUserRole(role: string) {
  //   return this.userRole().includes(role);
  // }

  // // public getRank() {
  // //   return this.user['rank'] || null;
  // // }

  // get auth() {
  //   if (this._auth) {
  //     return this._auth;
  //   }
  //   if (localStorage.getItem(DataBaseKeys.Token)) {
  //     this._auth = JSON.parse(
  //       atob(localStorage.getItem(DataBaseKeys.Token) || '')
  //     );
  //   } else {
  //     this._auth = null;
  //   }
  //   return this._auth || {};
  // }

  // set auth(val) {
  //   localStorage.setItem(DataBaseKeys.Token, btoa(JSON.stringify(val)));
  //   this._auth = { ...val };
  // }
}
