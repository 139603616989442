<div
  class="loginsection"
  fxFlex
>
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="center center"
  >
    <div
      class="wellcome"
      fxFlex
    >Create an account!</div>
    <div
      [formGroup]="signUpGroup"
      fxFlex
      ngStyle.lt-md="'padding-bottom':'50px'"
      fxLayoutAlign="center center"
    >
      <div class="loginCont">
        <div class="contentHeader">Sign Up</div>
        <div>Fill all fields so we can get some <br>info about you. We'll never send you spam</div>
        <div
          fxLayout="row"
          fxLayoutAlign="center"
          style="margin-top: 20px;"
        >
          <div class="envolop">
            <mat-icon [svgIcon]="'assets/icons/user.svg' | svgIconUrl"></mat-icon>
          </div>
          <input
            formControlName="name"
            type="text"
            class="email"
            placeholder="Name"
          >
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center"
          style="margin-top: 20px;"
        >
          <div class="envolop">
            <mat-icon [svgIcon]="'assets/icons/envelope.svg' | svgIconUrl"></mat-icon>
          </div>
          <input
            formControlName="email"
            type="email"
            class="email"
            placeholder="Email"
          >
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center"
          style="margin-top: 20px;"
        >
          <div class="envolop">
            <mat-icon [svgIcon]="'assets/icons/id-card.svg' | svgIconUrl"></mat-icon>
          </div>
          <input
            formControlName="username"
            type="text"
            class="email"
            placeholder="Username"
          >
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center"
          style="margin-top: 20px;"
        >
          <div class="envolop">
            <mat-icon [svgIcon]="'assets/icons/phone.svg' | svgIconUrl"></mat-icon>
          </div>
          <input
            formControlName="mobile"
            class="email"
            placeholder="Number"
          >
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center"
          style="margin-top: 20px;"
        >
          <div class="envolop">
            <mat-icon [svgIcon]="'assets/icons/unlock.svg' | svgIconUrl"></mat-icon>
          </div>
          <input
            formControlName="password"
            type="password"
            class="email"
            placeholder="Password"
          >
        </div>
        <div fxLayoutAlign="center">
          <button
          (click)="signUp(newsFeed)"
            class="btn"
          >Sign Up</button>
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center"
          style="margin-top: 15px;"
        >
          <input
            type="checkbox"
            id="vehicle1"
            name="vehicle1"
            value="accept"
          >
          <div>By signing up, you accept our&nbsp;<a style="  text-decoration: underline;">Terms</a>&nbsp;&amp;&nbsp;<a style="  text-decoration: underline;">Privacy Policy</a><br></div>
        </div><br>
        <hr>
        <div>Already have an account?</div>
        <div
          routerLink="/login"
          class="signUp"
        > Sign In</div>
      </div>
    </div>
  </div>
</div>
<mat-icon
  class="curvedDesign"
  [svgIcon]="'assets/icons/curvedDesign.svg' | svgIconUrl"
></mat-icon>

<ng-template #newsFeed>
  <div
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <h1>Token</h1>
    <div style="margin-bottom: 10px;">We sent you a token in your E-mail</div>
    <mat-form-field appearance="outline">
      <mat-label>Token</mat-label>

      <input
        matInput
        placeholder="Enter your token"
        #newsFeed
      >
      <!-- <mat-icon
        matSuffix
        style="color: rgb(199, 54, 54);"
      >feed</mat-icon> -->
    </mat-form-field>
    <button
      mat-button
      color="primary"
      (click)="closeToken(newsFeed.value)"
    >Next</button>
  </div>
</ng-template>
