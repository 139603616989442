<div
  fxLayout="column"
  class="w100p"
>
  <div style="position: relative;">
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      class="headerDesign"
    >
      <div
        fxFlex
        fxLayout="column"
        fxLayoutAlign="center start"
        fxLayoutAlign.lt-md="start center"
        fxLayoutGap="20px"
        style="z-index: 2;line-height: 1.5;"
      >
        <div
          class="Community white-fg w100p"
          ngStyle.lt-md="font-size : 28px;'"
          style="font-size: 64px;"
        >Get your own Community App</div>
        <div class="CommunityDeatil white-fg w100p">Create your own community. Have full control of your Community, Organisation or Group.
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutGap="10px"
          fxLayoutAlign="start center"
          class="w372"
        >
        <a href="https://play.google.com/store/apps/details?id=com.webiers.app.alpha" target="_blank">
          <img
            style="width: 48% !important;"
            fxFlex
            src="assets/img/google-play.png"
          >
        </a>
        <a href="https://apps.apple.com/us/app/webiers/id1575915362" target="_blank">
          <img
            style="width: 48% !important;"
            fxFlex
            src="assets/img/app-store.png"
          >
          </a>
        </div>
        <div
          class="w100p white-fg"
          style="font-style: italic;"
        >* Available on iPhone, iPad and all Android devices</div>
      </div>
      <div
        fxFlex
        class="phoneMockups"
      >
        <img
          class=" w100p"
          src="assets/img/Samsung Galaxy.png"
        >
      </div>
    </div>
    <mat-icon
      [svgIcon]="'assets/icons/design.svg' | svgIconUrl"
      class="design"
    >
    </mat-icon>
  </div>
  <div
    fxLayoutAlign="center start"
    fxLayout="row wrap"
  >
    <ng-container *ngFor="let item of counts;let i = index;">
      <div
        fxLayoutAlign="center center"
        fxFlex
        fxLayout="column"
      >
        <div class="UserMillion">{{item.value|async}}</div>
        <div>{{item.type}}</div>
      </div>
      <div
        *ngIf="i<3"
        class="h84 black-bg"
        fxFlex="1px"
        style="opacity: 0.15;"
      ></div>
    </ng-container>
  </div>
  <!-- What Makes Webiers Different? -->
  <div style="margin-top:125px">
    <div
      class="webiersGroup"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div
        class="webiersHeader mat-elevation-z3 "
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="center center"
      >
        <mat-icon
          style="color:#7c4fe0"
          [svgIcon]="'assets/icons/idea.svg' | svgIconUrl"
        >
        </mat-icon>
        <div style="color:#7c4fe0">Premium</div>
        <div>Features</div>
      </div>
      <div class="WebiersContent">What Makes Webiers Different?</div>
      <div
        class="w10p"
        style="height:1.5px;background:#7c4fe0; margin-bottom: 10px;"
      ></div>
      <div class="webiersDeatil">Webiers is made with the utmost care and professionalism. We aim at bringing content, people, and memberships collectively in a private and secure community platform. We pride ourselves on our people-centric model that puts YOU ahead of everything else.</div>
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        style="margin-top: 25px;"
      >
        <div
          class="card  mat-elevation-z3"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          *ngFor="let item of features"
        >
          <div><img
              class="featureImg"
              [src]="item.icon"
            ></div>
          <div class="featuresHeader">{{item.header}}</div>
          <div class="featuresDetail">{{item.detail}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- Make Your Device Manage Everything For You -->
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="center center"
    class="mainDevice"
  >
    <div
      fxLayout="column"
      fxFlex
      style="padding: 8%;"
      fxLayoutGap="10px"
    >
      <div class="deviceHeader">Make Your Device Manage Everything For You</div>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        *ngFor="let item of device"
      >
        <div
          class="iconBox"
          fxFlex="54px"
        >
          <mat-icon [svgIcon]="item.icon | svgIconUrl">
          </mat-icon>
        </div>
        <div class="iconDetail">{{item.header}}</div>
      </div>
    </div>
    <div fxFlex><img
        class="DeviceImg"
        src="assets/img/features_thumb.png"
        alt=""
      ></div>
  </div>
  <!-- Easily communicate with your membership using Webiers. -->
  <div
    fxLayout="row"
    fxLayout.lt-md="column-reverse"
    fxLayoutAlign="center center"
  >
    <div
      fxFlex
      style="padding: 50px;"
    ><img
        class="DeviceSecImg"
        src="assets/img/discover_thumb.png"
        alt=""
      ></div>
    <div
      fxLayout="column"
      fxFlex
      style="padding: 8%;"
      fxLayoutGap="10px"
    >
      <div class="deviceHeader">Easily communicate with your membership using Webiers.</div>
      <div class="devicecontent">Communication is key to the success of any brand. Webiers has all the tools you'll ever need to manage your community on a single platform. Communicate, plan events, and share content easily and without hassle.</div>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        *ngFor="let item of checkDevice"
      >
        <div style="color: #7c4fe0;">
          <mat-icon [svgIcon]="item.icon | svgIconUrl">
          </mat-icon>
        </div>
        <div class="iconDetail">{{item.header}}</div>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="10px"
      >
        <div
          class="iconBox"
          fxFlex="54px"
          *ngFor="let item of icon"
        >
          <mat-icon [svgIcon]="item.iconOne | svgIconUrl">
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
  <!-- How Webiers works? -->
  <div
    class="WebiersWorks"
    fxLayout="column"
  >
    <div class="webiresWorksHeader">How Webiers works?</div>
    <div class="webiresWorksContent">We developed a platform that is both easy to use and intuitive. With a well-designed user interface and vibrant colors, you'll make webiers your community's new home!</div>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
    >
      <div
        fxFlex
        class="WebiersWorksSection"
        fxLayout="row"
        fxLayout.lt-md="column"
        *ngFor="let item of webiersDetail;let i = index;"
        fxLayoutAlign="center center"
      >
        <div
          style="padding: 5%;"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxFlex
          fxLayoutGap="10px"
        >
          <div><img
              class="WebiersWorksIcon"
              [src]="item.icon"
            ></div>
          <div class="WebiersWorksheader">{{item.header}}</div>
          <div class="WebiersWorksContent">{{item.content}}</div>
        </div>
        <div *ngIf="i<2">
          <mat-icon
            ngStyle.lt-md="transform:rotate(90deg)"
            class="WebiersWorksSecIcon"
            ngClass.lt-md="WebiersWorksSecIconSM"
            ngClass.gt-sm="WebiersWorksSecIconLG"
          >arrow_forward_ios</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <!-- What Our Customers Are Saying -->
  <div style="margin-top:125px;">
    <div
      class="webiersGroup"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div
        class="webiersHeader mat-elevation-z3 "
        fxLayout="row"
        fxLayoutGap="15px"
        fxLayoutAlign="center center"
      >
        <mat-icon
          style="color:#7c4fe0"
          [svgIcon]="'assets/icons/idea.svg' | svgIconUrl"
        >
        </mat-icon>
        <div style="color:#7c4fe0">Customer's</div>
        <div>Reviews</div>
      </div>
      <div class="WebiersContent">What Our Customers Are Saying</div>
      <div
        class="w10p"
        style="height:1.5px;background:#7c4fe0; margin-bottom: 10px;"
      ></div>
      <div class="webiersDeatil">Webiers was met with a lot of positive reviews and comments. Our clients' satisfaction is a priority, so we're happy with the overwhelming feedback we get.</div>
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        style="margin-top: 25px;"
        fxLayoutGap="15px"
      >
        <div
          class="mama"
          fxFlex
          fxLayoutAlign="start start"
          *ngFor="let item of review"
          fxLayout="column"
        >
          <div
            fxLayout="row"
            style="padding: 15px;"
            fxLayoutAlign="start center"
          >
            <div *ngFor="let item of [1,2,3,4,5]">
              <mat-icon
                [svgIcon]="'assets/icons/starts.svg' | svgIconUrl"
                class="starDesign"
              ></mat-icon>
            </div>
            <div style="padding-left: 100px;"><img
                src="assets/img/quote.png"
                class="quotesImg"
              ></div>
          </div>
          <div
            class="startReview"
            fxFlex
            style="padding:20px;text-align:start;"
          >{{item.customerReview}}</div>
          <div class="desginOne"></div>
          <div class="designTwo">
            <div style="font-weight: 600;color: #222;font-size: 16px; text-align: start;padding: 10px;
            padding-left: 15px;">{{item.customer}}</div>
            <div style="color: #666 !important;font-weight: 600;font-size: 14px;
            text-align: start;padding-left: 15px;">{{item.customerComp}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Frequently Asked Questions -->
  <div
    fxLayout="column"
    class="w100p"
    fxLayoutAlign="center center"
  >
    <div class="WebiersContent">Frequently Asked Questions</div>
    <div
      class="w10p"
      style="height:1.5px;background:#7c4fe0; margin-bottom: 10px; margin-top: 10px;"
    ></div>
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutGap="25px"
      class="w100p"
      style="padding:50px;"
    >
      <div
        fxLayout="column"
        fxFlex
        fxLayoutGap="25px"
      >
        <div *ngFor="let item of askedQuestionsSectionOne">
          <div class="askedQuestions">{{item.question}}</div>
          <div class="askedAnswer">{{item.answer}}</div>
        </div>
      </div>
      <div
        fxLayout="column"
        fxFlex
        fxLayoutGap="25px"
      >
        <div>
          <div class="askedQuestions">How often will my app be updated?</div>
          <div class="askedAnswer">Our tech wizards are working around the clock to bring you new features and updates. You'll stay informed on all the new improvements, bug fixes, and new features as we roll them out.</div>
        </div>
        <div>
          <div class="askedQuestions">How much does it cost?</div>
          <div class="askedAnswer">We offer two different plans for our customers, each with its set of features - Basic Package & Enterprise Package.</div>
        </div>
        <div>
          <div class="askedQuestions">What’s the difference between the two packages</div>
          <div class="askedAnswer">Whether you choose the Basic Package or the Enterprise Package, you'll enjoy a host of features that are included in both. Our Basic Package contains the following features:</div>
        </div>
        <div class="askedAnswer">● News<br>● Messaging<br>● Document Management System<br>● Event Management System<br>● Social Login<br>● User Management With Groupings<br><br>With our Enterprise Package, you'll get access to all the features available in the Basic Package with these additions:<br><br>● Survey Module<br>● Online Claims Module<br>● Shopping Cart<br>... and many more.</div>
      </div>
    </div>
    <h2 style="font-weight: 500;color: #212529!important;font-size: 14px;line-height: 24px;">Haven't find suitable answer?&nbsp; <a
        href=""
        style="font-weight: 500;color: #212529!important;font-size: 14px;line-height: 24px;"
      >Tell us what you need.</a> </h2>
  </div>
  <!-- Webiers is available for all devices -->
  <div
    class="webiersDevices w100p"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <div
      fxFlex.lt-xs="100%"
      fxFlex.gt-xs="83%"
      fxFlex.gt-sm="66%"
    >
      <div class="webiersDevicesHeader">Webiers is available for all devices</div>
      <div class="webiersDevicescentent">Whether you plan on using an Apple or an Android phone, you’ll find that our app works on any of platform. The experience is the same wherever or whenever you want to use it!
      </div>
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        style="margin-top: 10px;"
      >
      <a href="https://play.google.com/store/apps/details?id=com.webiers.app.alpha" target="_blank">
        <img
          class="playstoreImgCollection"
          fxFlex
          src="assets/img/google-play.png"
        >
      </a>
        <a href="https://apps.apple.com/us/app/webiers/id1575915362" target="_blank">
        <img
          class="playstoreImgCollection"
          fxFlex
          src="assets/img/app-store.png"
        >
      </a>
      </div>
      <div class="webiersDevicesfooter">* Available on iPhone, iPad and all Android devices</div>
    </div>
  </div>
  <!-- Subscribe to get updates -->
  <div
    fxLayout="row"
    class="w100p"
    fxLayoutAlign="center center"
    style="padding: 10px;"
  >
    <div
      fxFlex.lt-xs="100%"
      fxFlex.gt-xs="83%"
      fxFlex.gt-sm="66%"
    >
      <div class="SubscribeHeader">Subscribe to get updates</div>
      <div class="SubscribeContent">Subscribe to our newsletter if you want to stay up to date with any new updates, bug fixes, or improvements!</div>
      <input
        class="SubscribeContentInput"
        type="email"
        placeholder="Enter Your email"
      >
      <button class="SubscribeContentBtn">Subscribe</button>
    </div>
  </div>
  <!-- Stay Tuned -->
  <!-- <div
    class="StayTunedContener"
    fxLayoutAlign="center center"
    fxLayout="column"
    style="padding:15px"
  >
    <div class="StayTunedHeader">Stay Tuned</div>
    <div
      class="w10p"
      style="height:1.5px;background:#7c4fe0; margin-bottom: 10px; margin-top: 10px;"
    ></div>
    <div class="StayTunedContener">If you have any questions, have feedback, or requests, please don’t hesitate to contact us from the form below. We’re looking forward to hearing from you!</div>
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      class="w100p"
    >
      <div
        fxFlex
        fxLayout="column"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
      >
        <div
          fxLayout="row"
          *ngFor="let item of icon"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
        >
          <div
            class="iconBox"
            fxFlex="54px"
          >
            <mat-icon [svgIcon]="item.iconTwo | svgIconUrl">
            </mat-icon>
          </div>
          <div>467 River Valley rd, S248352</div>
        </div>
      </div>
      <div
        fxFlex
        fxLayout="column"
        [ngStyle.lt-sm]="{'padding': '0'}"
        style="padding-left: 10%;padding-right: 10%;"
      ><br>
        <input
          class="formText"
          type="text"
          placeholder="Name"
        ><br>
        <input
          class="formText"
          type="email"
          placeholder="Email"
        ><br>
        <input
          class="formText"
          type="text"
          placeholder="Subject"
        ><br>
        <textarea
          class="formTextarea"
          placeholder="Message"
          cols="30"
          rows="10"
        ></textarea>
        <button class="SubscribeContentBtn">Send Message</button>
      </div>
    </div>
  </div> -->
