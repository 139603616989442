<div
  class="blogHeader"
  fxLayoutAlign="center center"
>
  <div class="blog">Blog</div>
</div>
<div
  fxLayout="row wrap"
  style="padding: 16px;"
>
  <div
    class="blogContainer"
    fxFlex="25%"
    fxFlex.lt-md="50%"
    fxFlex.lt-sm="100%"
    *ngFor="let item of news"
  >
    <div
      fxLayout="column"
      style="border-radius: 12px;"
      class="blogCard mat-elevation-z3"
      [routerLink]="['/',item.id,'view']"
    >
      <div><img
          *ngIf="item?.images && item?.images[0]"
          class="blogImg"
          [src]="item?.images[0]"
        ><img
          *ngIf="item.newsLink && item.newsLink[0]"
          class="blogImg"
          [src]="item?.newsLink[0].image"
        >
        <img
          *ngIf="item.video"
          [src]="item.video.image"
          class="blogImg"
        >
      </div>
      <div class="p8">
        <div
          fxLayout="row"
          class="userDetail"
        >
          <div>By {{item.author.username}}</div>
          <div
            fxFlex
            fxLayoutAlign="end start"
          >{{ item.createdAt}}</div>
        </div>
        <div class="blogTitile">{{item.title}}</div>
        <div>{{item.subTitle}}</div>
        <div style="float:right;color: blue;">Read More</div>
      </div>
    </div>
  </div>
</div>
<div>
  <mat-paginator
    [length]="resultsLength"
    [pageSize]="10"
  >
  </mat-paginator>
</div>
