const domain = 'https://www.app.webiers.com';
const APIUrl = domain + '/api';

export const urlResolver = (url: string) => (obj: any) => {
  return Object.keys(obj).reduce((a, b) => a.replace(`{${b}}`, obj[b]), url);
};

export class APIRoutes {
  static news = APIUrl + '/blog/webPost';
  static singleNews = urlResolver(APIUrl + '/blog/{id}/webView');
  static login = APIUrl + '/auth/login';
  static profile = APIUrl + '/auth/profile';
  static signup = APIUrl + '/users';
  static fbLogin = APIUrl + '/auth/fb';
  static VerifyEmailId = APIUrl + '/auth/verifyEmail';
  public static Registration = APIUrl + '/users';
  public static createComment = urlResolver(
    APIUrl + '/blog/{id}/comments/add'
  );
  public static getComment = urlResolver(APIUrl + '/blog/{id}/comments');
}
