import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  icon = [
    {
      iconOne: 'assets/icons/bell.svg',
      iconTwo: 'assets/icons/home.svg',
      compDetail: '467 River Valley rd, S248352',
    },
    {
      iconOne: 'assets/icons/envelope.svg',
      iconTwo: 'assets/icons/phone.svg',
      compDetail: '+65 91991600',
    },
    {
      iconOne: 'assets/icons/video.svg',
      iconTwo: 'assets/icons/msg.svg',
      compDetail: 'sales@webiers.com',
    },
  ];

  ngOnInit(): void {}
  constructor() {}
}
