import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { APIRoutes } from '../config/api.route';
import { map } from 'rxjs/operators';

class PersistentCache {
  private store: any = {};
  get(key: string) {
    if (!this.store[key]) {
      const item = localStorage.getItem(key);
      if (item) {
        this.store[key] = JSON.parse(item);
      }
    }
    return this.store[key];
  }
  set(key: string, value: any) {
    this.store[key] = value;
    localStorage.setItem(key, JSON.stringify(value));
  }
  has(key: string) {
    return !!this.get(key);
  }
  clear() {
    this.store = {};
    localStorage.clear();
  }
}
const LOCAL_DATA_KEYS = {
  TOKEN: 'z1',
  USER: 'z2',
  PROFILE: 'z3',
  BUSINESS_DETAILS: 'z4',
};

const AUTH_CACHE = new PersistentCache();
@Injectable({ providedIn: 'root' })
export class AuthService {

userDetail;

constructor(private http: HttpClient) {
  const details = localStorage.getItem('xag_to');
  if (details) {
    this.userDetail = JSON.parse(atob(details));
  }
}

  // userProfile;
  public getAuthToken() {
  return AUTH_CACHE.get(LOCAL_DATA_KEYS.TOKEN);
}


  public getNews(ix) {
  const jData = {
    filter: { condition: 'AND', rules: [], valid: true },
    config: { sort: 'id', order: 'DESC', page: ix + 1, limit: '10' },
  };
  const params = new HttpParams().append('jData', JSON.stringify(jData));
  return this.http.get(APIRoutes.news, { params });
}
  public getSingleNews(id) {
  return this.http.get(APIRoutes.singleNews({ id }));
}
  public login(data: any) {
  return this.http.post(APIRoutes.login, data);
}
  public signup(data: any) {
  return this.http.post(APIRoutes.signup, data);
}

  public fbLogin(data: any) {
  return this.http.post(APIRoutes.fbLogin, data);
}
  public verifyEmailId(email) {
  const data = { email };
  return this.http.post(APIRoutes.VerifyEmailId, data);
}

  public registration(userData) {
  return this.http.post(APIRoutes.Registration, userData, {}).pipe();
}

logout() {
  localStorage.clear();
  window.location.reload();
}
  public createComment(id, data, reply ?) {
  const commentData: any = { message: data };
  if (reply) {
    commentData.reply = reply;
  }
  return this.http.post(APIRoutes.createComment({ id }), commentData);
}

public getComment(id, jData, reply?) {
  // console.log(jData);
  let params = new HttpParams().append('jData', JSON.stringify(jData));
  if (reply) {
    params = params.append('reply', reply);
  }
  return this.http.get(APIRoutes.getComment({ id }), { params }).pipe(
    map((comments: any) => {
      comments.data = comments.data.map((comment) => {
        comment.reaction ||= {};
        comment.reaction.user = {
          hasLiked: comment.reactBy === 1,
          hasDisliked: comment.reactBy === 2,
        };
        return comment;
      });
      return comments;
    })
  );
}


  // public login(data: any) {
  //   return this.http.post(APIRoutes.login, data, {
  //     headers: DEFAULT_HEADERS,
  //   });
  // }

  public profile() {
  return this.http.get(APIRoutes.profile).pipe(
    tap((v: any) => {
      this.userDetail = v.data;
      localStorage.setItem('xag_to', btoa(JSON.stringify(this.userDetail)));
    })
  );
}



  // public getProfile(id) {
  //   return this.http.get(APIRoutes.getProfile + id);
  // }

  // public addPost(data: any) {
  //   return this.http.post(APIRoutes.addPost, data);
  // }
  // public Category() {
  //   return this.http.get(APIRoutes.Category);
  // }
  // public showPost() {
  //   return this.http.get(APIRoutes.showPost);
  // }

  // public selectedPost(id) {
  //   return this.http.get(APIRoutes.selectedPost + id);
  // }
  // public updatedPost(id, data) {
  //   return this.http.post(APIRoutes.updatedPost + id, data);
  // }
  // public deletePost(id) {
  //   return this.http.delete(APIRoutes.deletePost + id);
  // }
  // public comment(data) {
  //   return this.http.post(APIRoutes.comment, data);
  // }

  // public getComment(id) {
  //   return this.http.get(APIRoutes.getComment + id);
  // }
  // public reply(data) {
  //   return this.http.post(APIRoutes.reply, data);
  // }
  // public getReply(id) {
  //   return this.http.get(APIRoutes.getReply + id);
  // }
  // public reaction(data) {
  //   return this.http.post(APIRoutes.reaction, data);
  // }
  // public findUser(id) {
  //   return this.http.get(APIRoutes.findUser + id);
  // }
  // public chat(data) {
  //   return this.http.post(APIRoutes.chat, data);
  // }
  // public userChat(id) {
  //   return this.http.get(APIRoutes.userChat + id);
  // }
  // public userGroup() {
  //   return this.http.get(APIRoutes.userGroup);
  // }
}
